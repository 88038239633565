import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Button, Grid } from '@material-ui/core';

import Logo from 'components/logo/Logo.js.jsx'

import FormExtractor from '@dolnpm/form-extractor'
import {DataField} from '@dolnpm/data-fields-for'
import OmniLink from '@dolnpm/omni-link'

import img from 'static/lineup.png'

import BtnContainer from 'projects/dynamic/pages/back/pages/survey/components/btn-container/BtnContainer.js.jsx'

import Http from 'helpers/http'

import IKnowAboutProjectQuestion from '../.././components/i-know-about-project-question/IKnowAboutProjectQuestion.jsx'
import GreyBackgroundHack from 'components/grey-background-hack/GreyBackgroundHack.jsx'

const mapStateToProps = state => {
    const {fields, signInLink, deviseLinks} = state
    return {
        fields,
        signInLink,
        deviseLinks,
    }
};

const mapDispatchToProps = dispatch => ({});

class Landing extends React.Component {
    state = {
      hasData: false
    }

    componentDidMount() {
      const helper = new Http;
      const { signUpLink, showSignUp, match } = this.props
      const { params } = match
      const { ref } = params
      helper.getData({url: `/${Rails.acronym}/project_data/`}, {}, this.showData);
    }

    showData = (data) => {
      this.setState({hasData: true, ...data})
    }

    render() {
        const { signInLink, showSignUp, match } = this.props
        const { params } = match
        const { ref } = params
        const { project_name, project_description } = this.state
        return (
          <Grid item xs={12} sm={6} md={6} className='invite-form-2020'>
            <Logo style={{width: '200px', height: '60px', margin: '0 auto', marginBottom: '80px', marginTop: '20px'}} />

            <p>
              Welcome to AKOU.
            </p>

            <p>
              {project_name} is using AKOU to better understand the social impact they create through their work. Part of this process is exploring the social capital linked to their network.
            </p>

            <p>
              Create your account to start showing people why you value them as a connection in relation to {project_name}.
            </p>

            <p>
              <b>What is AKOU?</b>
              <br />
              With the AKOU app you can let people know why you appreciate them and see why they value their connection to you.
              <br />
              Doing this helps to uncover and prove the social capital linked to you, your connections and projects.
              <br />
              We are on a mission to make it easy to understand how social capital is created and the impact that it has.
            </p>

            <IKnowAboutProjectQuestion project_name={project_name} reference={ref} />

            <div className='lineup-image'>
              <img src={img} />
            </div>
            <GreyBackgroundHack />
          </Grid>
        )
    }
}

Landing.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
