import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import FormExtractor from '@dolnpm/form-extractor'
import {DataField} from '@dolnpm/data-fields-for'
import OmniLink from '@dolnpm/omni-link'

import Button from '@material-ui/core/Button';

import './styles.sass'

const mapStateToProps = state => {
    const {fields, signUpLink, deviseLinks, showSignUpLink} = state
    return {
        fields,
        signUpLink,
        deviseLinks,
        showSignUpLink
    }
};

const mapDispatchToProps = dispatch => ({});

class Sessions extends React.Component {
    render() {
        const { signUpLink, showSignUp, match } = this.props
        const { params } = match
        const { ref } = params

        const u = new URL(location.href)
        const join = u.searchParams.get('join')

        return (
          <React.Fragment>
            <h2>Log in to AKOU</h2>
            <div>
              <FormExtractor handle='sessions-form'>
                <DataField method='email' name='account' fields={this.props.fields} type='text' label='Email' />
                <DataField method='password' name='account' fields={this.props.fields} type='password' label='Password' />
                {ref && <input type='hidden' name='ref' value={ref} />}
                {ref && <input type='hidden' name='ref' value={ref} />}
                {Rails.acronym && <input type='hidden' name='acronym' value={Rails.acronym} />}

                <Button variant="contained" color='primary' type="submit" fullWidth={true}>
                  Sign in
                </Button>
                <div className='forgotten-password'>
                  {this.props.deviseLinks.map((link, index) => {
                    return (
                      <OmniLink key={link.href} to={link.href} link={link} />
                    )
                  })}
                </div>
                <br/>
                {/*
                  <div>
                    By creating an account you agree to our Terms, Data Policy and Cookies Policy.
                  </div>
                */}
                <br/>
                {/*<div>
                  Don't have an account?

                  <OmniLink className='underline sign-up-authbox' to={signUpLink.href} link={signUpLink}>
                    Sign up here
                  </OmniLink>
                </div>
                */}
              </FormExtractor>
            </div>
          </React.Fragment>
        )
    }
}

Sessions.propTypes = {
    fields: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sessions);
