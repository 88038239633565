import React from 'react'
import PropTypes from 'prop-types'

export default class GreyBackgroundHack extends React.Component {
    componentDidMount() {
      document.body.style.background = 'linear-gradient(135deg, #e9fafa, #fafbea)'
      document.body.style.backgroundAttachment= 'fixed';
    }

    componentWillUnmount() {
      document.body.style.background = 'none'
    }

    render() {
        const { children } = this.props
        return children || React.Fragment
    }
}
