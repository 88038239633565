import {createMuiTheme} from "@material-ui/core/styles/index";
import variables from 'aesthetics/sass-variables'

export default createMuiTheme({
      typography: {
       fontFamily: [
         'Work Sans',
         '-apple-system',
         'BlinkMacSystemFont',
         '"Segoe UI"',
         '"Helvetica Neue"',
         'Arial',
         'sans-serif',
         '"Apple Color Emoji"',
         '"Segoe UI Emoji"',
         '"Segoe UI Symbol"',
       ].join(','),
       fontWeight: 200
     },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#cfd734',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: '#0066ff',
            main: '#0044ff',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#ffcc00',
        },
        // error: will use the default color
    },
    typography: {
        useNextVariants: true,
    },
    bodyPaddingTop: 50,
    overrides: {
        MuiButton: {
            raisedPrimary: {
                color: 'black',
                background: '2px solid #cfd734',
            },
        },
        MuiLinearProgress: {
            root: {
                height: '20px'
            }
        },
        MuiInput: {
          underline: {
            '&:focus': { //underline color when hovered
              borderBottom: '2px solid #cfd734',
            },
            '&:after': {
              borderBottom: '2px solid #cfd734',
            },
          }
        },
    }
});
