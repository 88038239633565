import React from 'react'
import { Route, Switch } from 'react-router'

import { Grid, withWidth, Hidden } from '@material-ui/core'

import FittedGradImage from 'components/fitted-grad-image/FittedGradImage.jsx'
import AuthBox from './components/auth-box/AuthBox.jsx'
import img from '../.././images/social.png'

import './styles.sass'

const Auth = (props) => {
    const { width } = props
    return (
        <Grid container spacing={0} className='auth-container' justify="center" alignItems="center">
          <Hidden only={['xs']}>
            <AuthBox scope={Rails.acronym} containerClass='log-in-container' className='auth-box' />
          </Hidden>
          <Grid item xs={12} sm={6} md={8} className={['sm', 'md', 'lg', 'xl'].includes(width) ? 'session-gradient' : 'session-gradient-smaller'}>
            <Grid container spacing={0} justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} className='auth-gradient-container'>
                <img src={img} />
                <div className='fill-hack-text'>
                  <p>
                    Understand how you
                  </p>
                  <p>
                    make people feel
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Hidden only={['sm', 'md', 'lg', 'xl']}>
            <AuthBox scope={Rails.acronym} containerClass='log-in-container' className='auth-box' title='Log in to AKOU' />
          </Hidden>
        </Grid>
    )
}

export default withWidth()(Auth);
