import React from 'react'
import OmniLink from '@dolnpm/omni-link'

import './logo.sass'

const el = document.getElementById('root-url')
const rootUrl = el ? el.getAttribute('data') : '/'

class Logo extends React.PureComponent {
    render() {
        const { url, style } = this.props
        if (!url) {
          return (
            <div className="logo" style={style} />
          );
        }
        return (
            <OmniLink href={url} className='centred-logo'>
              <div className="logo" style={style} />
            </OmniLink>

        );
    }
}

export default Logo;
