import React from 'react'
import { Route, Switch } from 'react-router'
import { connect } from 'react-redux'

import Sessions from './pages/sessions/Sessions.jsx'
import ForgottenPassword from './pages/forgotten-password/ForgottenPassword.jsx'
import ResetPassword from './pages/reset-password/ResetPassword.jsx'

import './auth.sass'

function create(scope, url) {
    return scope ? `/${scope}${url}` : url
}

class Auth extends React.Component {
    render() {
        const { className, scope } = this.props
        return (
            <div className={className}>
              <Switch>
                <Route exact path='/' component={Sessions} />
                <Route exact path='/sign-in' component={Sessions} />
                <Route exact path='/forgotten-password' component={ForgottenPassword} />
                <Route exact path='/reset-password' component={ResetPassword} />

                <Route exact path='/:acronym' component={Sessions} />

                <Route exact path='/:acronym/sign-in' component={Sessions} />
                <Route exact path='/:acronym/forgotten-password' component={ForgottenPassword} />
                <Route exact path='/:acronym/reset-password' component={ResetPassword} />

                <Route exact path='/:acronym/sign-in/:ref' component={Sessions} />
                <Route exact path='/:acronym/forgotten-password/:ref' component={ForgottenPassword} />
                <Route exact path='/:acronym/reset-password/:ref' component={ResetPassword} />
              </Switch>
            </div>
        )
    }
}

export default Auth
