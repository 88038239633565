import {agent} from '@dolnpm/react-redux-init-app'
const {encode, requests, setToken} = agent

//const get = (url) => (params) => requests.get(url, params)
// const getPermissions = get(`/${Rails.acronym}/xhr/get_permissions/get-permissions`)


const ajax = (type, options, data, callback, failCallback) => {
    requests[type.toLowerCase()](options.url, data).then(
      (data) => {
          callback(JSON.parse(data.response));
      }, (error) => {
          failCallback(JSON.parse(error.response.body.response));
      }
    )
    // $.ajax({
    //     method: type,
    //     url: options.url,
    //     data: data
    // }).done((data) => {
    //     callback(JSON.parse(data.response));
    // }).fail((jqXHR, textStatus, errorThrown) => {
    //     failCallback(JSON.parse(jqXHR.responseText));
    // });
}

export default class Http {
    constructor() {}

    getData(options, data, callback, failCallback) { ajax('GET', options, data, callback, failCallback) }
    postData(options, data, callback, failCallback) { ajax('POST', options, data, callback, failCallback) }
    patchData(options, data, callback, failCallback) { ajax('PATCH', options, data, callback, failCallback) }
    deleteData(options, data, callback, failCallback) { ajax('DELETE', options, data, callback, failCallback) }
}
