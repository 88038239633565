import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import FormExtractor from '@dolnpm/form-extractor'
import { DataField } from '@dolnpm/data-fields-for'
import OmniLink from '@dolnpm/omni-link'

import { Button, Grid, Checkbox } from '@material-ui/core';

const inputProps = { 'aria-label': 'primary checkbox', 'style': {'background': '#CED734'} }

const Check = ({ toggle, checked }) => {
  return (
    <Checkbox color='primary' checked={checked} onChange={toggle} inputProps={inputProps} />
  )
}

const mapStateToProps = state => {
    const {fields, signInLink, deviseLinks} = state
    return {
        fields,
        signInLink,
        deviseLinks,
    }
};

const mapDispatchToProps = dispatch => ({});

const standard = {variant: 'standard'}

class Form extends React.Component {
    state = {
      terms: false,
      invites: false,
      emails: false
    }

    toggle = (prop) => {
      const _this = this
      return () => {
        _this.setState({ [prop]: !_this.state[prop] })
      }
    }

    render() {
        const { fields, signInLink, match, deviseLinks } = this.props
        const { terms, invites, emails } = this.state
        const { toggle } = this


        const search = window.location.search && window.location.search.split('?')[1]
        let project_id = null
        if (search) {
            const url = new URL(window.location)
            project_id = url.searchParams.get("project_id");
        }

        const { params } = match
        const { ref } = params

        return (
          <div>
            <FormExtractor handle='registrations-form'>
              <DataField method='first_name' name='account' fields={fields} type='text' label='First name' opts={standard} />
              <DataField method='surname' name='account' fields={fields} type='text' label='Last name' opts={standard} />
              <DataField method='email' name='account' fields={fields} type='text' label='Email Address' opts={standard} />
              <DataField method='password' name='account' fields={fields} type='password' label='Password' opts={standard} />
              <DataField method='password_confirmation' name='account' fields={fields} type='password' label='Password Confirmation' opts={standard} />
              {project_id && <input type='hidden' name='project_id' value={project_id} />}
              {ref && <input type='hidden' name='ref' value={ref} />}
              <input type='hidden' name='acronym' value={Rails.acronym} />

              <div className='login-details'>
                <Check toggle={toggle('terms')} checked={terms} />
                I agree to the <OmniLink href='/legal/terms'>AKOU terms of service</OmniLink>, <OmniLink href='/legal/data'>Data Policy</OmniLink> and <OmniLink href='/legal/cookies'>Cookies Policy</OmniLink>.
              </div>

              <div className='login-details'>
                <Check toggle={toggle('invites')} checked={invites} />
                <input type='hidden' name='always_invite' value={invites} />
                I wish to be invited to take impact surveys related to the projects I am connected to
              </div>

              <div className='login-details'>
                <Check toggle={toggle('emails')} checked={emails} />
                <input type='hidden' name='agree_to_contact' value={emails} />
                I agree to be emailed by the AKOU team to provide feedback about the projects I am connected to
              </div>

              <Button disabled={!terms} variant="contained" color='primary' type="submit" fullWidth={true}>
                Create account
              </Button>
            </FormExtractor>

            <div className='login-details'>
              Already have an account?
              Sign in here <OmniLink to={signInLink.href} link={signInLink} />
            </div>
          </div>
        )
    }
}

Form.propTypes = {
    fields: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
