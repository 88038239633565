import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import FormExtractor from '@dolnpm/form-extractor'
import {DataField} from '@dolnpm/data-fields-for'
import OmniLink from '@dolnpm/omni-link'

import { Button, Grid, Checkbox } from '@material-ui/core';

import Form from './components/form/Form.jsx'
import GreyBackgroundHack from 'components/grey-background-hack/GreyBackgroundHack.jsx'
import Logo from 'components/logo/Logo.js.jsx'

import './styles.sass'

export default (props) => {
  const { match } = props
  const { params } = match
  const { acronym } = params

    if (!acronym) {
      return (
        <h2>If you're an internal tester for AKOU, please report how you got here. Thank you</h2>
      )
    }

    return (
      <Grid item xs={12} sm={6} md={4} className='registrations-form-2020'>
        <Logo style={{width: '200px', height: '60px', margin: '0 auto', marginBottom: '20px', marginTop: '20px'}} />
        <h3>Create your Akou account and see how you bring value to your connections</h3>
        <Form match={props.match} />
        <GreyBackgroundHack />
      </Grid>
    )
}
