import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Button, Grid } from '@material-ui/core';

import Logo from 'components/logo/Logo.js.jsx'

import FormExtractor from '@dolnpm/form-extractor'
import {DataField} from '@dolnpm/data-fields-for'
import OmniLink from '@dolnpm/omni-link'

import img from 'static/lineup.png'

import BtnContainer from 'projects/dynamic/pages/back/pages/survey/components/btn-container/BtnContainer.js.jsx'
import GreyBackgroundHack from 'components/grey-background-hack/GreyBackgroundHack.jsx'

import Http from 'helpers/http'

export default (props) => {
    return (
      <Grid item xs={12} sm={6} md={6} className='invite-form-2020'>
        <Logo style={{width: '200px', height: '60px', margin: '0 auto', marginBottom: '80px', marginTop: '20px'}} />

        <p>
          Unfortunately you’ve indicated that you’re not aware of this project so we’re not able to let you join it.
        </p>

        <p>
          Not all is lost - you can still get involved with AKOU in the following ways:
        </p>

        <p>
          We’re working on ways to let people not connected to a project to join
          the AKOU app. To be the first to know when you're able to do so
          <OmniLink className='underline' href='https://akou.co.uk/interested-in-akou-sign-up/'> sign up here</OmniLink>.
        </p>

        <p>
          Want to set up your own project?
          <OmniLink className='underline' href='https://akou.co.uk/i-want-to-set-up-a-project-on-akou/'> Speak to the AKOU team</OmniLink>
            - we’d love to get you up and running.
        </p>

        <p>
          Find out how we’re helping people to understand social capital,
          keep up with our news and updates and everything app related -
          <OmniLink className='underline' href='https://akou.co.uk/newsletter-signup-from-app/'> register here</OmniLink>.
        </p>

        <div className='lineup-image'>
          <img src={img} />
        </div>
        <GreyBackgroundHack />
      </Grid>
    )
}
