import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Button, Grid } from '@material-ui/core';

import Logo from 'components/logo/Logo.js.jsx'

import FormExtractor from '@dolnpm/form-extractor'
import {DataField} from '@dolnpm/data-fields-for'
import OmniLink from '@dolnpm/omni-link'

import img from 'static/lineup.png'

import BtnContainer from 'projects/dynamic/pages/back/pages/survey/components/btn-container/BtnContainer.js.jsx'

import Http from 'helpers/http'

import IKnowAboutProjectQuestion from '../.././components/i-know-about-project-question/IKnowAboutProjectQuestion.jsx'
import GreyBackgroundHack from 'components/grey-background-hack/GreyBackgroundHack.jsx'

import './styles.sass'

const mapStateToProps = state => {
    const {fields, signInLink, deviseLinks} = state
    return {
        fields,
        signInLink,
        deviseLinks,
    }
};

const mapDispatchToProps = dispatch => ({});

class Invite extends React.Component {
    state = {
      hasData: false
    }

    componentDidMount() {
      const helper = new Http;
      const { signUpLink, showSignUp, match } = this.props
      const { params } = match
      const { ref } = params
      helper.getData({url: `/${Rails.acronym}/invite_data/${ref}`}, {}, this.showData);
    }

    showData = (data) => {
      this.setState({hasData: true, ...data})
    }

    render() {
        const { hasData, noInvite, not_found, invited_first, invitee_full, invitee_first, statement, project_name, project_description, exchange } = this.state
        if (!hasData) {
          return (
            <Grid item xs={12} sm={6} md={4} className='registrations-form-2020'>
              <h3>Receiving Invitation</h3>
            </Grid>
          )
        }

        const { signInLink, showSignUp, match } = this.props
        const { params } = match
        const { ref } = params
        return (
          <Grid item xs={12} sm={6} md={6} className='invite-form-2020'>
            <Logo style={{width: '200px', height: '60px', margin: '0 auto', marginBottom: '80px', marginTop: '20px'}} />

            {ref &&
              <div>
                <div>
                  <p>
                    Hi {invited_first},
                  </p>
                  <p>
                    Welcome to AKOU. You’ve been added as a valued connection by {invitee_full}.
                  </p>
                  <p>
                    {statement}.
                  </p>
                  <p>
                    This relationship has been mapped within the {project_name} project on AKOU.
                  </p>
                  <p>
                    The {project_name} project is {project_description}.
                  </p>
                  <p>
                    Create your account to see more about what has been said about you and start showing others why you value them.
                  </p>
                  <IKnowAboutProjectQuestion project_name={project_name} reference={ref} />
                </div>
              </div>
            }

            <div className='lineup-image'>
              <img src={img} />
            </div>

            <GreyBackgroundHack />
          </Grid>
        )
    }
}

Invite.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
