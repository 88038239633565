import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import FormExtractor from '@dolnpm/form-extractor'
import {DataField} from '@dolnpm/data-fields-for'
import OmniLink from '@dolnpm/omni-link'

import Button from '@material-ui/core/Button';

const mapStateToProps = state => {
    const {fields, signInLink, deviseLinks} = state
    return {
        fields,
        signInLink,
        deviseLinks,
    }
};

const mapDispatchToProps = dispatch => ({});

class ForgottenPassword extends React.Component {
    render() {
        const { signInLink } = this.props

        return (
          <React.Fragment>
            <h2>Forgotten Password</h2>
            <div>
              <FormExtractor handle='forgotten-password-form'>
                  <DataField method='email' name='account' fields={this.props.fields} type='text' label='Email' />
                  <Button variant="contained" color='primary' type="submit" fullWidth={true}>
                      Send Reminder
                  </Button>
                  <div className='forgotten-password-form-links'>
                    <OmniLink to={signInLink.href} link={signInLink} />
                  </div>
              </FormExtractor>
            </div>
          </React.Fragment>
        )
    }
}

ForgottenPassword.propTypes = {
    fields: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPassword);
