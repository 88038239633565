import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid } from '@material-ui/core';
import OmniLink from '@dolnpm/omni-link'

import img from 'static/lineup.png'

export default (props) => {
    const { project_name, reference } = props
    const ref = reference

    

    return (
      <div>
        <h3>I know about {project_name} and am happy to be part of this experiment on the AKOU app</h3>

        <Grid container spacing={0} justify="center" alignItems="center">
          <Grid item xs={12} sm={6} md={6} className='invite-form-2020'>
            <div className="next-buttons">
              <OmniLink to={`/${Rails.acronym}/follow-up${ref ? `/${ref}` : ''}`}>
                <Button variant="contained" color='primary' type="submit" fullWidth={true}>
                  No
                </Button>
              </OmniLink>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className='invite-form-2020'>
            <div className="next-buttons">
              <OmniLink to={`/${Rails.acronym}/sign-up${ref ? `/${ref}` : ''}`}>
                <Button variant="contained" color='primary' type="submit" fullWidth={true}>
                  Yes
                </Button>
              </OmniLink>
            </div>
          </Grid>
        </Grid>
      </div>
    )
}
