import React from 'react'
import { Route, Switch } from 'react-router'

import { Grid } from '@material-ui/core'

import AuthPage from './pages/auth-page/AuthPage.jsx'
import Registrations from './pages/registrations/Registrations.jsx'
import Invite from './pages/invite/Invite.jsx'
import Landing from './pages/landing/Landing.jsx'
import FollowUp from './pages/follow-up/FollowUp.jsx'

function create(scope, url) {
    return scope ? `/${scope}${url}` : url
}

export default () => {
    const scope = Rails.acronym
    return (
        <Grid container spacing={0} justify="center" alignItems="center">
          <Switch>
            <Route exact path='/' component={AuthPage} />
            <Route exact path='/sign-in' component={AuthPage} />
            <Route exact path='/sign-up' component={Registrations} />
            <Route exact path='/forgotten-password' component={AuthPage} />
            <Route exact path='/reset-password' component={AuthPage} />

            <Route exact path='/:acronym' component={AuthPage} />
            <Route exact path='/:acronym/sign-in' component={AuthPage} />
            <Route exact path='/:acronym/sign-up' component={Registrations} />
            <Route exact path='/:acronym/forgotten-password' component={AuthPage} />
            <Route exact path='/:acronym/reset-password' component={AuthPage} />

            <Route exact path='/:acronym/sign-in/:ref' component={AuthPage} />
            <Route exact path='/:acronym/sign-up/:ref' component={Registrations} />
            <Route exact path='/:acronym/forgotten-password/:ref' component={AuthPage} />
            <Route exact path='/:acronym/reset-password/:ref' component={AuthPage} />

            <Route exact path='/:acronym/invite/:ref' component={Invite} />
            <Route exact path='/:acronym/landing' component={Landing} />
            <Route exact path='/:acronym/follow-up' component={FollowUp} />
            <Route exact path='/:acronym/follow-up/:ref' component={FollowUp} />
          </Switch>
        </Grid>
    )
}
